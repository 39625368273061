<template>
  <step-lesson-layout :title="$t('module1.part4.lesson3.title')">
    <template v-slot:content>
      <lesson-video-block :poster="require('@/assets/module1/part4/poster-rai-video.svg')"
        video="/videos/rai-cee.mp4"
        subtitles="/subtitles/rai-cee.vtt"
        :button-label="$t('module1.part4.lesson3.videoButtonLabel')"
        :enable-resume="false">
      </lesson-video-block>

      <div class="app-text-block">
        <p class="title">{{ $t('module1.part4.lesson3.block1.title') }}</p>
        <div class="acteurs-cee">
          <div v-for="acteur in acteursCee"
            :key="acteur.name"
            class="acteur"
          >
            <div class="image-wrapper">
              <img :src="acteur.image" :alt="acteur.name">
            </div>
            <div class="content">
              <p class="label" v-html="acteur.label"></p>
              <p class="paragraphe">
                {{ acteur.content }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('training.global.partFinished') }}
        </p>
        <app-button-layout @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import LessonVideoBlock from '@/components/training/LessonVideoBlock'

export default {
  name: 'Module1Part4Lesson3',
  components: { LessonVideoBlock, StepLessonLayout, AppButtonLayout },
  setup () {
    const { goToNextStep, resumeLater } = useTrainingUtils()
    return { resumeLater, goToNextStep }
  },
  data () {
    return {
      acteursCee: [
        {
          name: 'etat',
          label: this.$t('module1.part4.lesson3.acteurs.etat.label'),
          content: this.$t('module1.part4.lesson3.acteurs.etat.content'),
          image: require('@/assets/module1/part4/acteurs/etat.svg')
        },
        {
          name: 'pncee',
          label: this.$t('module1.part4.lesson3.acteurs.pncee.label'),
          content: this.$t('module1.part4.lesson3.acteurs.pncee.content'),
          image: require('@/assets/module1/part4/acteurs/pncee.svg')
        },
        {
          name: 'oblige',
          label: this.$t('module1.part4.lesson3.acteurs.oblige.label'),
          content: this.$t('module1.part4.lesson3.acteurs.oblige.content'),
          image: require('@/assets/module1/part4/acteurs/oblige.svg')
        },
        {
          name: 'delegataire',
          label: this.$t('module1.part4.lesson3.acteurs.delegataire.label'),
          content: this.$t('module1.part4.lesson3.acteurs.delegataire.content'),
          image: require('@/assets/module1/part4/acteurs/delegataire.svg')
        },
        {
          name: 'artisan',
          label: this.$t('module1.part4.lesson3.acteurs.artisan.label'),
          content: this.$t('module1.part4.lesson3.acteurs.artisan.content'),
          image: require('@/assets/module1/part4/acteurs/artisan.svg')
        },
        {
          name: 'beneficiaire',
          label: this.$t('module1.part4.lesson3.acteurs.beneficiaire.label'),
          content: this.$t('module1.part4.lesson3.acteurs.beneficiaire.content'),
          image: require('@/assets/module1/part4/acteurs/beneficiaire.svg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.app-text-block {
  .title {
    padding: 0 $space-l;
  }
  .acteurs-cee {
    display: grid;
    gap: 2px;
    .acteur {
      padding: $space-l $space-sm;
      background-color: #fff;
      display: flex;
      align-items: center;
      &:first-child {
        border-radius: $radius $radius 0 0;
      }
      &:last-child {
        border-radius: 0 0 $radius $radius;
      }
      .image-wrapper {
        min-width: 110px;
        display: flex;
        justify-content: center;
        margin-right: $space-sm;
      }
      .label {
        color: $c-secondary;
        font-size: $fz-l;
        font-weight: $fw-l;
        margin-bottom: $space-s;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .app-text-block {
    .acteurs-cee {
      grid-template-columns: repeat(2, 1fr);
      gap: $space-s;
      .acteur {
        border-radius: $radius;
      }
    }
  }
}
</style>
